html {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #293237;
  /* background-image: url('/images/pattern.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0 0 5px rgba(92, 202, 255, 0.5); */
}

[hidden],
.hidden {
  display: none;
}

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

.page {
  max-width: 800px;
  margin: 30px auto;
  -webkit-animation: slide-up 0.4s ease;
          animation: slide-up 0.4s ease;
  /* min-height: 100vh; */
  position: relative;
  background: white;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media (max-width: 850px) {
  .page {
    max-width: 700px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 500px) {
  .page {
    margin: 0;
    border-radius: 0;
  }
}

.page__logo {
  display: block;
  margin: 30px;
  max-width: calc(100% - 60px);
  /* margin: 0 auto; */
}

.page__content {
  margin: 30px;
}

@media (max-width: 500px) {
  .page__content {
    margin: 10px;
  }
}

.button {
  display: block;
  margin: 30px auto;
  line-height: 3.125;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  padding: 0 4em;
  background-color: #ffa300;
  color: white;
  -webkit-transition: box-shadow 366ms;
  transition: box-shadow 366ms;
}

.button:hover,
.button:focus,
.button:active {
  box-shadow: 0 0 0 5px rgba(92, 202, 255, 0.5);
}

.button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.selectionInput {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.selectionInput__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.selectionInput__label {
  outline: none;
}

/* Create a custom checkbox */
.selectionInput__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  outline: 0;
  background-color: #eee;
  -webkit-transition: background-color 360ms;
  transition: background-color 360ms;
}

/* On mouse-over, add a grey background color */
.selectionInput:hover .selectionInput__input ~ .selectionInput__checkmark,
.selectionInput:focus .selectionInput__input ~ .selectionInput__checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.selectionInput__input:checked ~ .selectionInput__checkmark {
  background-color: #ffa300 !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.selectionInput__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.selectionInput__input:checked ~ .selectionInput__checkmark:after {
  display: block;
}

.selectionInput__checkmark--checkbox {
  border-radius: 4px;
}

/* Style the checkmark/indicator */
.selectionInput__checkmark--checkbox:after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.selectionInput__checkmark--radio {
  border-radius: 50%;
}

/* Style the indicator (dot/circle) */
.selectionInput__checkmark--radio:after {
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
}

.question {
}

.question__header {
  display: flex;
  align-items: center;
  /* border-bottom: thin solid #ccc; */
  padding: 15px 0;
}

.question__header__headline {
  background: #ffa300;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  color: white;
}

.question__header__remaining {
  flex: 1 1;
  text-align: right;
  font-weight: bold;
}

.question__clock {
  display: flex;
  align-items: center;
}

.question__clock h2 {
  flex: 1 1;
}

.question__clock__time {
  line-height: 1.5;
  background-color: #ffa300;
  color: white;
  text-align: center;
  max-width: 175px;
  padding: 10px;
  border-radius: 4px;
  font-weight: bolder;
  align-self: center;
}

.question__clock__minutes::after {
  content: '\A0:\A0';
}

.question__content--answered {
  opacity: 0.5;
}

.question__text img {
  border: thin solid #ccc;
  display: block;
  max-width: calc(100% - 30px);
  margin-top: 15px;
  padding: 15px;
}

.question__answers {
  line-height: 24px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.question__answers__answer {
  line-height: 24px;
  padding: 10px;
  margin: 15px 0;
  border: thin solid #ccc;
  border-left: 4px solid #ffa300;
  cursor: pointer;
  border-radius: 4px;
  -webkit-transition: background 360ms, color 260ms;
  transition: background 360ms, color 260ms;
}

.question__answers__answer:hover,
.question__answers__answer:focus,
.question__answers__answer--active {
  background: #ffa300;
  color: white;
  box-shadow: 0 0 0 5px rgba(92, 202, 255, 0.5);
}

.notfound {
  padding: 30px;
  text-align: center;
}

/* .notfound a,
.notfound  a:hover .notfound a:active,
.notfound a:focus {
  color: #ffa300;
} */

.notfound img {
  max-width: 80%;
  display: block;
  margin: 30px auto;
}

.Toastify__toast-body {
  padding: 15px;
}

.rc-progress-line {
  max-height: 10px;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
  -webkit-transition: all 360ms;
  transition: all 360ms;
}

.spinner--opacity {
  opacity: 0.7;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffa300;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

#outdated {
  font-family: 'Open Sans', 'Segoe UI', sans-serif;
  position: absolute;
  background-color: #f25648;
  color: white;
  display: none;
  overflow: hidden;
  left: 0;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  padding: 0 24px 24px 0;
}
#outdated.fullscreen {
  height: 100%;
}
#outdated .vertical-center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
#outdated h6 {
  font-size: 25px;
  line-height: 25px;
  margin: 12px 0;
}
#outdated p {
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}
#outdated #buttonUpdateBrowser {
  border: 2px solid white;
  color: white;
  cursor: pointer;
  display: block;
  margin: 30px auto 0;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  width: 230px;
}
#outdated #buttonUpdateBrowser:hover {
  background-color: white;
  color: #f25648;
}
#outdated .last {
  height: 20px;
  position: absolute;
  right: 70px;
  top: 10px;
  width: auto;
  display: inline-table;
}
#outdated .last[dir='rtl'] {
  left: 25px !important;
  right: auto !important;
}
#outdated #buttonCloseUpdateBrowser {
  display: none;
}

.form {
  position: relative;
  padding: 10px 20px;
  margin: 10px auto;
  padding: 20px;
  background: #f4f7f8;
  border-radius: 8px;
}

.form fieldset {
  border: none;
}

.form legend {
  font-size: 1.5em;
  /* margin-bottom: 10px; */
}

.form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form input[type='text'],
.form input[type='email'],
.form textarea {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  margin-top: 8px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: #e8eeef;
  color: #8a97a0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  margin-bottom: 30px;
  color: black;
}

.form input[type='text']:focus,
.form input[type='email']:focus,
.form textarea:focus {
  background: #d2d9dd;
}

.form button[type='submit'] {
  position: relative;
  display: block;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  width: 50%;
  margin-bottom: 10px;
}

.info-message {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #ffa300;
  background-color: #ffffff;
  border-color: #ffa300;
}

